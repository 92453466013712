import { render, staticRenderFns } from "./ordersList.vue?vue&type=template&id=ffe2b208&scoped=true"
import script from "./ordersList.vue?vue&type=script&lang=js"
export * from "./ordersList.vue?vue&type=script&lang=js"
import style0 from "./ordersList.vue?vue&type=style&index=0&id=ffe2b208&prod&scoped=true&lang=css"
import style1 from "./ordersList.vue?vue&type=style&index=1&id=ffe2b208&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffe2b208",
  null
  
)

export default component.exports