<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 20px">
      <div
        class="statusBox"
        :class="activeStatus == 'All' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'All'"
      >
        Усі
      </div>
      <div
        class="statusBox"
        :class="activeStatus == 'Payed' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'Payed'"
      >
        Активні
      </div>
      <div
        class="statusBox"
        :class="activeStatus == 'PreBooking' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'PreBooking'"
      >
        Заброньовані
      </div>
      <div
        class="statusBox"
        :class="activeStatus == 'PayInBus' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'PayInBus'"
      >
        Оплата при посадці
      </div>
      <div
        class="statusBox"
        :class="activeStatus == 'Canceled' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'Canceled'"
      >
        Скасовані
      </div>
    </v-row>
    <loader v-if="showLoader" />
    <v-data-table
      v-else
      style="margin-top: 32px"
      :headers="headers"
      :items="ordersList"
      :loading="loading"
      hide-default-footer
      hide-default-header
      disable-sort
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ви не додали жодного маршруту"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            head.sortable && head.sortable !== false
              ? setSortField(head.value)
              : ''
          "
          v-for="(head, index) in props.headers"
          :key="index"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                color: #4b5262;
                text-align: center;
                font-family: 'MacPaw Fixel';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item, index }">
        <v-row no-gutters justify="center"></v-row>
        <span class="tableItem">{{ `${page * 24 - 24 + index + 1}` }}</span>
      </template>
      <template v-slot:[`item.route`]="{ item }">
        <span
          class="tableItem pointer"
          style="color: #144fa9"
          @click="
            $router.push({
              name: 'editTrip',
              params: { id: item.schedule?.id },
            })
          "
          >{{ item?.departure?.translations?.[0]?.name }} -
          {{ item?.destination?.translations?.[0]?.name }}</span
        >
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <span class="tableItem">
          {{ item?.user?.first_name }} {{ item?.user?.last_name }}</span
        >
      </template>
      <template v-slot:[`item.paid_at`]="{ item }">
        <span class="tableItem" v-if="item.paid_at !== null">
          {{
            new Date(item?.paid_at).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}&nbsp;{{
            new Date(item?.paid_at).toLocaleDateString("uk-UA", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Kiev",
            })
          }}</span
        >
        <span class="tableItem" v-else>
          {{
            new Date(item?.created_at).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}&nbsp;{{
            new Date(item?.created_at).toLocaleDateString("uk-UA", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Kiev",
            })
          }}</span
        >
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div style="width: 190px">
          <v-row no-gutters justify="center">
            <div
              class="orderStatusBox statusName"
              style="width: max-content"
              :style="
                item.status == 'Payed'
                  ? 'color: #149e51; background: rgba(20, 158, 81, 0.10); '
                  : item.status == 'Booking' ||
                    item.status == 'PreBooking' ||
                    item.status == 'PayInBus'
                  ? 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
                  : 'color: #DF3A3A; background: rgba(223, 58, 58, 0.10);'
              "
            >
              {{
                item.status == "Payed"
                  ? "Оплачено"
                  : item.status == "PayInBus"
                  ? "Оплата при посадці"
                  : item.status == "Booking" || item.status == "PreBooking"
                  ? "Заброньовано"
                  : "Скасовано"
              }}
            </div>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div style="min-width: 145px">
          <v-row no-gutters align="center" justify="end">
            <div
              class="iconBackground"
              style="margin-right: 12px; width: max-content; padding: 8px"
              @click="(isShowDetailOrderDrawer = true), (detailOrder = item)"
            >
              <p class="detailBtnText">Детальніше</p>
            </div>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="iconBackground"
                  @click="
                    $router.push({ name: 'editTrip', params: { id: item.id } })
                  "
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#828282"
                    class="pointer"
                    @click="
                      (symlinks = item.symlinks),
                        (isShowDetailOrderDrawer = true)
                    "
                    >mdi-dots-vertical</v-icon
                  >
                </div>
              </template>
              <span>Деталі</span>
            </v-tooltip> -->
          </v-row>
        </div>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />

    <detail-order-drawer
      v-if="isShowDetailOrderDrawer"
      :order="detailOrder"
      @close="isShowDetailOrderDrawer = false"
    />
  </v-col>
</template>
      
      <script>
import DetailOrderDrawer from "@/components/UI/detailOrderDrawer.vue";
import Loader from "@/components/UI/Loader.vue";
import pagination from "@/components/UI/pagination.vue";
import debounce from "lodash/debounce";
import orderHistoryService from "@/requests/Admin/orderHistoryService.js";
export default {
  components: { Loader, DetailOrderDrawer, pagination },
  data: () => ({
    sortBy: "",
    sortDesc: true,
    headers: [
      {
        text: "№",
        align: "center",
        value: "id",
        sortable: false,
      },
      { text: "Рейс", value: "route", align: "start", sortable: false },
      {
        text: "Номер квитка",
        value: "order_ref_id",
        align: "start",
        sortable: false,
      },
      {
        text: "Дата купівлі",
        value: "paid_at",
        align: "start",
        sortable: true,
      },
      {
        text: "Вартість",
        value: "final_price",
        align: "center",
        sortable: false,
      },
      {
        text: "Статус квитка",
        value: "status",
        align: "center",
        sortable: true,
      },
      { text: "Опції", value: "action", sortable: false, align: "center" },
    ],
    isShowDetailOrderDrawer: false,
    showLoader: true,
    loading: false,
    detailOrder: {},
    ordersList: [],
    page: 1,
    quantityPage: 1,
    search: [],
    activeStatus: "All",
  }),
  props: {
    searchByAll: {
      require: true,
    },
    dateSearch: {
      require: true,
    },
  },
  mounted() {
    this.search.push(`manager_id[eq]:${this.$route.params.id}`);
    this.getOrders();
  },
  methods: {
    async getOrders() {
      await orderHistoryService
        .getOrderHistory(
          this.page,
          JSON.stringify(this.search)
            .replaceAll('"', "")
            .replaceAll(",", ";")
            .replaceAll(".", ","),
          this.sortBy,
          this.sortDesc ? "DESC" : "ASC"
        )
        .then((res) => {
          if (res.status == "Success") {
            this.ordersList = res.data;
            this.quantityPage = res.pagination?.meta?.last_page;
            this.showLoader = false;
            this.loading = false;
          }
        });
    },
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getOrders();
    },
    setSortField(sortBy) {
      this.sortBy = sortBy;
      this.sortDesc = !this.sortDesc;
    },
    sortOrders: debounce(function () {
      this.loading = true;
      this.getOrders();
    }, 300),
    editTrip() {
      this.$emit("editTrip");
    },
  },
  watch: {
    sortBy: {
      handler() {
        this.sortOrders();
      },
    },
    sortDesc: {
      handler() {
        this.sortOrders();
      },
    },
    activeStatus: {
      handler() {
        this.showLoader = true;
        if (this.activeStatus !== "All") {
          if (this.activeStatus == "Canceled") {
            this.search.push(`status[in]:Canceled.NotPayed`);
          } else {
            this.search.push(`status[eq]:${this.activeStatus}`);
          }
        } else {
          this.search = this.search.filter((item) => !item.includes("status"));
        }
        this.getOrders();
      },
    },
    searchByAll: {
      handler() {
        this.showLoader = true;
        this.search = this.search.filter((item) => !item.includes("all"));
        if (this.searchByAll !== "") {
          this.search.push(`all[like]:${this.searchByAll}`);
        } else {
          this.search = this.search.filter((item) => !item.includes("all"));
        }
        this.getOrders();
      },
    },
    dateSearch: {
      handler() {
        this.showLoader = true;
        if (this.dateSearch !== "") {
          this.search.push(`${this.dateSearch}`);
        } else {
          this.search = this.search.filter((item) => !item.includes("paid_at"));
        }
        this.getOrders();
      },
    },
  },
};
</script>
      
      <style scoped>
.orderStatusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
.detailBtnText {
  color: #4b5262;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.iconBackground:hover .detailBtnText {
  color: #fff;
}
</style>
      <style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
.v-pagination__item .v-pagination__item--active {
  background-color: red !important;
}
</style>